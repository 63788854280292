import { defineStore } from "pinia";

export const useGatewayStore = defineStore("gateway", {
  state: () => ({
    gateways: [],
    cur_gateway: null,
  }),
  getters: {
    getGateways(state) {
      return state.gateways;
    },
    getGatewayById: (state) => {
      return (gateway_id: string) =>
        state.gateways.find((gateway) => gateway.gateway_id === gateway_id);
    },
  },
  actions: {
    async reset() {
      this.gateways = [];
      this.cur_gateway = null;
    },
    async fetchGateways() {
      const response = await fetch(
        new URL("/api/gateway", this.$apiUrl).toString(),
        {
          method: "GET",
          headers: this.default_headers,
          credentials: "include",
        }
      );
      if (response.status == 401) {
        console.log(response);
        this.$router.push("/");
      } else {
        const data = await response.json();
        console.log(data);
        if (data["status"] == "success") {
          this.gateways = data.records;
        }
      }
    },
  },
});
