<template>
  <div>
    <NavigationBar></NavigationBar>
  </div>
  <div class="maidoaplus-downshifted-content">
    <RouterView> </RouterView>
  </div>
  <div class="maidoaplus-footer text-muted small">Version: {{ version }}</div>
</template>

<script setup>
import { ref } from "vue";
import NavigationBar from "../components/v1/NavigationBar.vue";
import packageInfo from "../../package.json";

const version = ref(packageInfo.version);
</script>

<style>
@import "../assets/maidoaplus.css";
.maidoaplus-downshifted-content {
  padding-top: 66px;
}
.maidoaplus-footer {
  width: 100%;
  z-index: 100;
  text-align: right;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 5px;
}
</style>
