import { createApp } from "vue";

import router from "./router";

import AppWrapper from "./AppWrapper.vue";
const app = createApp(AppWrapper);

app.config.globalProperties.$apiUrl = new URL(
  process.env.VUE_APP_MAIDOAPLUS_API_BASE_URL
);

app.config.globalProperties.$defaultHeaders = {
  "X-Client": "console",
  "Content-Type": "application/json",
};

app.use(router);

import { createPinia } from "pinia";
const pinia = createPinia();
pinia.use(({ store }) => {
  store.$apiUrl = app.config.globalProperties.$apiUrl;
});
pinia.use(({ store }) => {
  store.$router = router;
});
app.use(pinia);

import PrimeVue from "primevue/config";
app.use(PrimeVue);

import ToastService from "primevue/toastservice";
app.use(ToastService);

import ConfirmationService from "primevue/confirmationservice";
app.use(ConfirmationService);

import BasicMenu from "primevue/menu";
import MenuBar from "primevue/menubar";
import DataTable from "primevue/datatable";
import DataColumn from "primevue/column";
import InputText from "primevue/inputtext";
import FormButton from "primevue/button";
import DataPanel from "primevue/panel";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FieldSet from "primevue/fieldset";
import InplaceDisplay from "primevue/inplace";
import ConfirmPopup from "primevue/confirmpopup";
import AutoComplete from "primevue/autocomplete";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";

app.component("BasicMenu", BasicMenu);
app.component("MenuBar", MenuBar);
app.component("DataTable", DataTable);
app.component("DataColumn", DataColumn);
app.component("InputText", InputText);
app.component("FormButton", FormButton);
app.component("DataPanel", DataPanel);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("FieldSet", FieldSet);
app.component("InplaceDisplay", InplaceDisplay);
app.component("ConfirmPopup", ConfirmPopup);
app.component("AutoComplete", AutoComplete);
app.component("DropDown", Dropdown);
app.component("DialogBox", Dialog);
app.component("ToastMessage", Toast);

app.mount("#app");

import JsonViewer from "vue-json-viewer";

// Import JsonViewer as a Vue.js plugin
app.use(JsonViewer);

//theme
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
//core
import "primevue/resources/primevue.min.css";
//icons
import "primeicons/primeicons.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import "vue-json-viewer/style.css";

import axios from "./axios";
import { useSessionStore } from "stores/v1/session.ts";
const session = useSessionStore();

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    session.fetchSessionUser();
    return error;
  }
);
