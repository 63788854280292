import { defineStore } from "pinia";

export const usePipelineStore = defineStore("pipeline", {
  state: () => ({
    pipelines: [],
  }),
  getters: {
    getPipelines(state) {
      return state.pipelines;
    },
    getPipelineById: (state) => {
      return (schema_id) =>
        state.pipelines.find((pipeline) => pipeline.schema_id == schema_id);
    },
  },
  actions: {
    async reset() {
      this.pipelines = [];
    },
    async fetchPipelines() {
      const response = await fetch(
        new URL("/api/enrichment-schema", this.$apiUrl),
        {
          method: "GET",
          headers: this.default_headers,
          credentials: "include",
        }
      );
      if (response.status == 401) {
        console.log(response);
        this.$router.push("/");
      } else {
        const data = await response.json();
        console.log(data);
        if (data["status"] == "success") {
          this.pipelines = data.records;
          console.log(this.pipelines);
        }
      }
    },
  },
});
