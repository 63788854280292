import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { getCurrentInstance } from "vue";
import axios from "@/axios";

export const useSessionStore = defineStore("session", () => {
  const globalProps = getCurrentInstance()?.appContext.config.globalProperties;

  interface Organization {
    name: string;
    topic: string;
    index: string;
    kafka_url: string;
    created: Date;
  }

  const _organization = ref<Organization | null>(null);

  const session_user = ref({
    username: null,
    email: null,
    organization: null,
    roles: null,
  });

  async function reset() {
    session_user.value.username = null;
    session_user.value.email = null;
    session_user.value.organization = null;
    session_user.value.roles = null;
    _organization.value = null;
  }

  const isLoggedIn = computed(() => Boolean(session_user.value.username));
  const organization = computed(() => {
    if (isLoggedIn.value && _organization.value == null) {
      fetchOrganization();
    }
    return _organization.value;
  });

  const user = computed(() => {
    if (session_user.value.username) {
      return session_user.value;
    }
    return null;
  });

  async function login(username: string, password: string) {
    let url = "/api/v1/session/login?";
    url += new URLSearchParams({
      cookie: "true",
    }).toString();

    const response = await axios.post(url, {
      username: username,
      password: password,
    });
    if (response.status == 200) {
      fetchSessionUser();
      fetchOrganization();
    }
  }

  async function logout() {
    const response = await fetch(
      new URL("/api/v1/session/logout", globalProps?.$apiUrl).toString(),
      {
        method: "POST",
        headers: globalProps?.$defaultHeaders,
        credentials: "include",
      }
    );

    if (response.status == 200) {
      reset();
    }
    return response;
  }

  async function fetchOrganization() {
    const url = "/api/v1/session/organization";
    const response = await axios.get(url);
    if (response.status == 200) {
      const resp = response.data;
      _organization.value = resp.data;
      console.log(_organization.value);
    }
  }

  async function fetchSessionUser() {
    const response = await fetch(
      new URL("/api/v1/session/user", globalProps?.$apiUrl).toString(),
      {
        method: "GET",
        headers: globalProps?.$defaultHeaders,
        credentials: "include",
      }
    );

    if (response.status == 200) {
      const resp = await response.json();
      session_user.value.username = resp.data.username;
      session_user.value.email = resp.data.email;
      session_user.value.organization = resp.data.organization;
      session_user.value.roles = resp.data.roles;
    } else {
      reset();
    }
    return response;
  }
  return {
    user,
    organization,
    session_user,
    fetchSessionUser,
    isLoggedIn,
    login,
    logout,
    reset,
  };
});
