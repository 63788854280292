import { createRouter, createWebHashHistory } from "vue-router";

const GatewayOverview = () => import("../components/GatewayOverview.vue");
const GatewayDetailView = () => import("../components/GatewayDetailView.vue");
const PipelineOverview = () => import("../components/PipelineOverview.vue");
const PipelineDetailView = () => import("../components/PipelineDetailView.vue");
const ContextOverview = () => import("../components/ContextOverview.vue");
const ContextDetailView = () => import("../components/ContextDetailView.vue");

const SwaggerView = () => import("views/v1/SwaggerView.vue");
const HomeView = () => import("views/v1/HomeView.vue");
const ApiView = () => import("../views/ApiView.vue");
const ApiKeyView = () => import("../views/ApiKeyView.vue");
const ContextView = () => import("../views/ContextView.vue");
const ContextMainView = () => import("../views/ContextMainView.vue");
const ContextEnrichmentView = () =>
  import("../components/ContextEnrichmentView.vue");

const routes = [
  { path: "/", redirect: "/app" },
  {
    path: "/app",
    name: "appMain",
    children: [
      {
        path: "",
        redirect: "/app/home",
      },
      {
        path: "home",
        component: HomeView,
      },
      {
        path: "gateway",
        component: GatewayOverview,
      },
      {
        path: "gateway/:id",
        name: "gatewayDetailView",
        component: GatewayDetailView,
      },
      {
        path: "pipeline",
        component: PipelineOverview,
      },
      {
        path: "pipeline/:id",
        name: "pipelineDetailView",
        component: PipelineDetailView,
      },
      {
        path: "context",
        component: ContextOverview,
      },
      {
        path: "context/:id",
        name: "contextDetailView",
        component: ContextView,
        children: [
          {
            path: "overview",
            component: ContextDetailView,
          },
          {
            path: "main",
            component: ContextMainView,
          },
          {
            path: "enrichment-history",
            component: ContextEnrichmentView,
          },
        ],
      },
      {
        path: "api",
        component: ApiView,
        children: [
          {
            path: "docs",
            component: SwaggerView,
          },
          {
            path: "keys",
            component: ApiKeyView,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

export default router;
