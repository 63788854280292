<template>
  <div id="admin-console">
    <form class="login" id="login_form">
      <img
        src="https://maidoaplus.com/ui/logo_maidoa_plus.svg"
        class="img-fluid"
      />
      <input type="text" placeholder="Eメール・ユーザー名" v-model="email" />
      <input type="password" placeholder="パスワード" v-model="password" />
      <button type="button" @click="login()">ログイン</button>
      <span :hidden="!try_again"> 入力内容に誤りがあります。 </span>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useSessionStore } from "stores/v1/session.ts";
import { onMounted, onUnmounted } from "vue";

const sessionStore = useSessionStore();

const email = ref("");
const password = ref("");
const try_again = ref(false);

onMounted(() => {
  const backgroundColor = "#4e73df";
  document.body.style.backgroundColor = backgroundColor;
});
onUnmounted(() => {
  document.body.style.backgroundColor = null;
});

async function login() {
  await sessionStore.login(email.value, password.value);
  if (!sessionStore.isLoggedIn) {
    try_again.value = true;
  }
}
</script>

<style scoped>
@import "../assets/login.css";
</style>
