import { ref } from "vue";
import { getCurrentInstance } from "vue";
import { defineStore } from "pinia";
import _ from "lodash";

import { useSessionStore } from "./session";

export const useApiKeyStore = defineStore("apikey", () => {
  const globalProps = getCurrentInstance()?.appContext.config.globalProperties;
  const session = useSessionStore();

  const apikeys = ref([]);
  const apikeys_map = ref({});

  async function reset() {
    apikeys.value = [];
    apikeys_map.value = {};
  }

  async function fetchApiKeys() {
    const url = new URL("/api/v1/apikey/", globalProps?.$apiUrl);
    const response = await fetch(url.toString(), {
      method: "GET",
      headers: globalProps?.$defaultHeaders,
      credentials: "include",
    });
    if (response.status == 200) {
      const data = await response.json();
      apikeys.value = data.data;
      apikeys_map.value = _.keyBy(data.data, "id");
    } else {
      session.fetchSessionUser();
    }
  }

  async function postApiKey(name: string, description: string) {
    const url = new URL("/api/v1/apikey/", globalProps?.$apiUrl);
    const response = await fetch(url.toString(), {
      method: "POST",
      headers: globalProps?.$defaultHeaders,
      credentials: "include",
      body: JSON.stringify({ name, description }),
    });
    return response;
  }

  async function deleteApiKey(apikey_id: string) {
    const url = new URL("/api/v1/apikey/" + apikey_id, globalProps?.$apiUrl);
    const response = await fetch(url.toString(), {
      method: "DELETE",
      headers: globalProps?.$defaultHeaders,
      credentials: "include",
    });
    return response;
  }

  return {
    apikeys,
    apikeys_map,
    fetchApiKeys,
    postApiKey,
    deleteApiKey,
    reset,
  };
});
