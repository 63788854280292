<template>
  <MenuBar :model="navItems" class="maidoaplus-navbar">
    <template #start>
      <img
        alt="logo"
        src="https://maidoaplus.com/ui/logo_maidoa_plus.svg"
        height="50"
      />
    </template>
    <template #end>
      <a
        href="https://marubeni.box.com/s/yhtc2kkenrzk0ncx6o3sggo66exxd2ve"
        target="_blank"
      >
        マニュアル
      </a>
      <button
        class="mt-10 btn btn-light"
        type="button"
        label="Toggle"
        @click="toggle"
        aria-haspopup="true"
        aria-controls="overlay_menu"
      >
        <Avatar icon="pi pi-user" class="mr-2" shape="circle" />
        {{ sessionStore.session_user.username }}
      </button>
      <BasicMenu
        ref="menu"
        id="overlay_menu"
        :popup="true"
        :model="userMenuItems"
      >
        <template #start>
          <div class="maidoaplus-userinfo">
            <div class="p-item">
              <i class="pi pi-envelope" />
              {{ sessionStore.session_user.email }}
            </div>
            <div class="p-item">
              <i class="pi pi-building" />
              {{ sessionStore.session_user.organization }}
            </div>
          </div>
        </template>
      </BasicMenu>
    </template>
  </MenuBar>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { useSessionStore } from "stores/v1/session";
import { useRoute } from "vue-router";

const route = useRoute();

const isActive = (path: string) => {
  return route.path.startsWith(path);
};

import Avatar from "primevue/avatar";

const sessionStore = useSessionStore();
const navItems = computed(() => [
  {
    label: "ホーム",
    icon: "pi pi-fw pi-home",
    to: "/app/home",
    class: isActive("/app/home") ? "p-menuitem p-focus" : "p-menuitem",
  },
  {
    label: "コンテキスト",
    icon: "pi pi-fw pi-table",
    to: "/app/context",
    class: isActive("/app/context") ? "p-menuitem p-focus" : "p-menuitem",
  },
  {
    label: "API",
    icon: "pi pi-fw pi-sort-alt",
    to: "/app/api/docs",
    class: isActive("/app/api") ? "p-menuitem p-focus" : "p-menuitem",
  },
  {
    label: "ゲートウェイ",
    icon: "pi pi-fw pi-sitemap",
    to: "/app/gateway",
    class: isActive("/app/gateway") ? "p-menuitem p-focus" : "p-menuitem",
  },
  {
    label: "パイプライン",
    icon: "pi pi-fw pi-file-import",
    to: "/app/pipeline",
    class: isActive("/app/pipeline") ? "p-menuitem p-focus" : "p-menuitem",
  },
]);

const menu = ref();
const userMenuItems = ref([
  {
    label: "ログアウト",
    icon: "pi pi-sign-out",
    command: sessionStore.logout,
  },
]);
async function toggle(event: MouseEvent) {
  menu.value.toggle(event);
}
</script>

<style>
.p-menubar.maidoaplus-navbar {
  background: #f8f8f8;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  width: 100%;
  z-index: 100;
}
.maidoaplus-userinfo {
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
}
.maidoaplus-userinfo .p-item {
  padding: 3px;
}
</style>
