<template>
  <!DOCTYPE html>
  <div v-if="sessionStore.isLoggedIn">
    <MainView></MainView>
  </div>
  <div v-else>
    <LoginView> </LoginView>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useSessionStore } from "stores/v1/session.ts";
import { setupLogoutWatcher } from "stores/main.ts";

import LoginView from "./views/LoginView.vue";
import MainView from "./views/MainView.vue";

const sessionStore = useSessionStore();

onMounted(() => {
  sessionStore.fetchSessionUser();
  setupLogoutWatcher();
});
</script>

<style>
body {
  overflow-y: scroll; /* Show vertical scrollbar */
}
</style>
