import { watch } from "vue";
import { useSessionStore } from "stores/v1/session";
import { useApiKeyStore } from "stores/v1/apikeys";
import { useContextStore } from "./contexts";
import { useFieldMapStore } from "stores/v1/field-map";
import { useGatewayStore } from "./gateways";
import { usePipelineStore } from "./pipelines";
import { useSystemStore } from "./v1/system";

export function setupLogoutWatcher() {
  const session = useSessionStore();
  const apikeyStore = useApiKeyStore();
  const contextStore = useContextStore();
  const fieldmapStore = useFieldMapStore();
  const gatewayStore = useGatewayStore();
  const pipelineStore = usePipelineStore();
  const systemStore = useSystemStore();

  watch(
    () => session.isLoggedIn,
    (isLoggedIn) => {
      if (!isLoggedIn) {
        apikeyStore.reset();
        contextStore.reset();
        fieldmapStore.reset();
        gatewayStore.reset();
        pipelineStore.reset();
        systemStore.reset();
      }
    },
    { immediate: true }
  );
}
