import { ref } from "vue";
import { defineStore } from "pinia";

import { useSessionStore } from "./session";
import axios from "@/axios";

export const useFieldMapStore = defineStore("field-map", () => {
  const field_map = ref({});
  const session = useSessionStore();

  async function reset() {
    field_map.value = {};
  }

  async function fetchFieldMap() {
    const url = "/api/v1/field-map/";
    const response = await axios.get(url);
    if (response.status == 200) {
      const resp = await response.data;
      field_map.value = resp.data;
    } else {
      session.fetchSessionUser();
    }
  }

  return { field_map, fetchFieldMap, reset };
});
