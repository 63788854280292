import { ref } from "vue";
import { getCurrentInstance } from "vue";
import { defineStore } from "pinia";
import _ from "lodash";
import axios from "../axios";

import { useSessionStore } from "stores/v1/session";

export const useContextStore = defineStore("context", () => {
  const globalProps = getCurrentInstance()?.appContext.config.globalProperties;

  interface Context {
    description: string;
    // eslint-disable-next-line
    enrichment_data: any;
    // eslint-disable-next-line
    enrichment_map: any;
    id: string;
    name: string;
    sync_status: string[];
  }

  interface ContextRecord {
    name: string;
    description: string;
    // eslint-disable-next-line
    enrichment_map: any;
  }

  interface ContextMap {
    [key: string]: Context;
  }

  interface AnyMap {
    // eslint-disable-next-line
    [key: string]: any;
  }

  const context_map = ref<ContextMap>({});
  const _original_context_map = ref<ContextMap>({});
  const _context_enrichment_history_map = ref<AnyMap>({});

  async function reset() {
    context_map.value = {};
    _original_context_map.value = {};
    _context_enrichment_history_map.value = {};
  }

  const isContextChanged = (context_id: string) => {
    if (Object.keys(_original_context_map.value).includes(context_id)) {
      return (
        JSON.stringify(_original_context_map.value[context_id]) !=
        JSON.stringify(context_map.value[context_id])
      );
    }
    return false;
  };

  const getContextEnrichmentHistory = async (context_id: string) => {
    const url = "/api/v1/context/" + context_id + "/enrichment-history";
    const response = await axios.get(url);
    if (response.status == 200) {
      const resp = response.data;
      _context_enrichment_history_map.value[context_id] = resp.data.items;
      return _context_enrichment_history_map.value[context_id];
    }
    return [];
  };

  async function fetchContexts() {
    const response = await fetch(
      new URL("/api/v1/context/", globalProps?.$apiUrl).toString(),
      {
        method: "GET",
        headers: globalProps?.$defaultHeaders,
        credentials: "include",
      }
    );
    if (response.status != 200) {
      const session = useSessionStore();
      session.fetchSessionUser();
    } else {
      const resp = await response.json();
      context_map.value = _.keyBy(resp.data, "id");
      _original_context_map.value = JSON.parse(
        JSON.stringify(context_map.value)
      );
    }
  }
  async function putContextById(context_id: string) {
    const body = {
      description: context_map.value[context_id].description,
      enrichment_map: context_map.value[context_id].enrichment_map,
      name: context_map.value[context_id].name,
    };

    return fetch(
      new URL("/api/v1/context/" + context_id, globalProps?.$apiUrl).toString(),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
      }
    );
  }
  async function postContext(body: ContextRecord) {
    return fetch(new URL("/api/v1/context/", globalProps?.$apiUrl).toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include",
    });
  }
  async function postContextPipelineById(
    context_id: string,
    description: string
  ) {
    return fetch(
      new URL(
        "/api/v1/context/" + context_id + "/actions/sync_pipeline",
        globalProps?.$apiUrl
      ).toString(),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ description: description }),
      }
    );
  }
  async function postContextPipelineHook() {
    return fetch(
      new URL(
        "/api/v1/context/actions/sync_hook",
        globalProps?.$apiUrl
      ).toString(),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
  }
  return {
    context_map,
    _original_context_map,
    isContextChanged,
    fetchContexts,
    putContextById,
    postContextPipelineById,
    postContextPipelineHook,
    postContext,
    getContextEnrichmentHistory,
    reset,
  };
});
