import { ref, computed } from "vue";
import { defineStore } from "pinia";
import axios from "@/axios";

import { useSessionStore } from "./session";

export const useSystemStore = defineStore("system", () => {
  const session = useSessionStore();

  interface DataPoint {
    count: number;
    end: Date;
    start: Date;
  }

  const _histogram = ref<DataPoint[] | undefined>(undefined);
  console.log("value:", _histogram.value);

  const histogram = computed(() => {
    console.log(_histogram.value);
    if (session.isLoggedIn && !_histogram.value) {
      console.log("adf");
      fetchHistogram();
    }
    return _histogram.value;
  });

  async function reset() {
    _histogram.value = undefined;
  }

  async function fetchHistogram() {
    const url =
      "/api/v1/context/histogram" +
      "?" +
      new URLSearchParams({
        timeframe: "3d",
      }).toString();

    const response = await axios.get(url);
    if (response.status == 200) {
      const resp = await response.data;

      // eslint-disable-next-line
      _histogram.value = resp.data.map((d: any) => {
        return {
          count: d.count,
          start: new Date(d.start),
          end: new Date(d.end),
        };
      });
    }
  }

  return {
    histogram,
    reset,
    fetchHistogram,
  };
});
